
export default {
  data: () => ({
    homeTaglineData: [],
    isLoading: true,
    itemKey: 'home.tagline',
    dialog: false,
  }),
  async created() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      this.isLoading = true
      const res = await this.$services.webSetting.getItemKey({
        itemKey: this.itemKey,
      })
      if (res.success) {
        this.homeTaglineData = res.data
      } else {
        this.homeTaglineData = []
      }
      this.isLoading = false
    },
  },
}
