import { render, staticRenderFns } from "./Currency.vue?vue&type=template&id=1f2df9dc&scoped=true&"
import script from "./Currency.vue?vue&type=script&lang=js&"
export * from "./Currency.vue?vue&type=script&lang=js&"
import style0 from "./Currency.vue?vue&type=style&index=0&id=1f2df9dc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f2df9dc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CurrencyUp: require('/usr/src/app/src/components/icons/CurrencyUp.vue').default,CurrencyDown: require('/usr/src/app/src/components/icons/CurrencyDown.vue').default,CurrencyNormal: require('/usr/src/app/src/components/icons/CurrencyNormal.vue').default})
