
export default {
  data: () => ({
    data: [],
  }),
  computed: {
    getPriceHint() {
      if (this.data.PRICE_HINT > 0) {
        return this.data.PRICE_HINT
      } else if (this.data.PRICE_HINT < 0) {
        return this.data.PRICE_HINT
      } else {
        return this.data.PRICE_HINT
      }
    },
    getMovingPrecentage() {
      if (this.getUpOrDown === 'up') {
        return this.data.MOVING_PERCENTAGE
      } else if (this.getUpOrDown === 'down') {
        return this.data.MOVING_PERCENTAGE
      }
      return this.data.MOVING_PERCENTAGE
    },
    getUpOrDown() {
      if (parseInt(this.data.MOVING_PERCENTAGE) > 0) {
        return 'up'
      } else if (parseInt(this.data.MOVING_PERCENTAGE) < 0) {
        return 'down'
      } else {
        return ''
      }
    },
  },
  mounted() {
    this.isLoading = true
    this.getStockInformation()
    this.isLoading = false
  },
  methods: {
    async fetchData() {
      const res = await this.$services.stockInformation.getStockInformation()
      if (res.success) {
        this.data = res.data
        this.$store.commit('general/SET_STOCK_INFORMATION', this.data)
      } else {
        this.data = []
      }
    },
    async getStockInformation() {
      if (this.$store.getters['general/getStockInformation']) {
        this.data = this.$store.getters['general/getStockInformation']
      } else {
        await this.fetchData()
      }
    },
  },
}
